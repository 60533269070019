import { Text, Title } from "@clipboard-health/ui-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, Container, Stack, TextareaAutosize, TextField } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

export const workedBreakAuthorizationSchema = z.object({
  reason: z
    .string({
      errorMap: () => ({
        message: "Please enter a detailed explanation that is between 60 and 300 characters.",
      }),
    })
    .min(60)
    .max(300),
});
export type WorkedBreakAuthorizationParams = z.infer<typeof workedBreakAuthorizationSchema>;

export interface WorkedBreakRequestCreationPageProps {
  onCancel: () => void;
  onSubmit: (data: WorkedBreakAuthorizationParams) => void;
  facilityName: string;
}

export function WorkedBreakRequestCreationPage(
  props: WorkedBreakRequestCreationPageProps
): JSX.Element {
  const { onCancel, onSubmit, facilityName } = props;

  const formMethods = useForm<WorkedBreakAuthorizationParams>({
    resolver: zodResolver(workedBreakAuthorizationSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const reason = register("reason");

  return (
    <Container maxWidth="xl" sx={{ height: "100%" }}>
      <FormProvider {...formMethods}>
        <form style={{ height: "100%" }} onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="column" height="100%" sx={{ textSizeAdjust: "100%" }}>
            <Stack direction="column" paddingTop={2} spacing={2} flexGrow={1}>
              <Title component="h1">Why didn&apos;t you take a complete break?</Title>
              <Text bold>{facilityName} requires that you take a 30-minute unpaid break.</Text>
              <Text>
                Please provide a detailed explanation for why you worked through your mandatory
                break. If a facility staff member instructed you to do so, include their name and
                title.
              </Text>
              <Text>
                Your explanation should address why your incomplete break was consistent with the
                facility’s mandatory break policy.
              </Text>
              <Text>
                Payments for worked break time undergo an additional three business day approval
                process where your explanation provided here is reviewed by {facilityName}.{" "}
                <u>
                  <b>Note that this is separate from standard shift payment processing.</b>
                </u>
              </Text>
              <Box paddingY={2}>
                <TextField
                  {...reason}
                  fullWidth
                  label="Explanation *"
                  InputProps={{ inputComponent: TextareaAutosize, inputProps: { minRows: 5 } }}
                  error={Boolean(errors.reason?.message)}
                  helperText={errors.reason?.message}
                />
              </Box>
            </Stack>
            <Stack direction="column" justifyContent="space-between" spacing={2} paddingY={2}>
              <Button fullWidth variant="contained" type="submit" size="small">
                Confirm break payment request
              </Button>
              <Button fullWidth variant="outlined" size="small" onClick={onCancel}>
                Go Back
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </Container>
  );
}
