import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";
import { z } from "zod";

import { shiftSchema } from "../types";

const getShiftResponseSchema = z.object({
  success: z.boolean(),
  response: shiftSchema,
  error: z.unknown().nullable(),
});

export type GetShiftResponse = z.infer<typeof getShiftResponseSchema>;

export function getShiftPath(shiftId: string) {
  return `/v1/shifts/myShifts/details/${shiftId}`;
}

export function useGetShift(
  shiftId: string,
  options: UseGetQueryOptions<GetShiftResponse> = {}
): UseQueryResult<GetShiftResponse> {
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}${getShiftPath(shiftId)}`,
    responseSchema: getShiftResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_SHIFT_FAILURE,
    },
    ...options,
  });
}
