import { Image, Text } from "@clipboard-health/ui-react";
import { Button, Stack } from "@mui/material";
import cbhBunnyLogo from "@src/appV2/lib/assets/images/cbhBunny.svg";
import { useHistory } from "react-router-dom";

export function WorkingShiftPlaceholder() {
  const history = useHistory();

  return (
    <Stack alignItems="center" spacing={2} textAlign="center" sx={{ p: 1 }}>
      <Image alt="Clipboard Health logo" src={cbhBunnyLogo} width="160px" />
      <Text variant="body2">We can&apos;t find the shift that you&apos;re looking for.</Text>
      <Button
        variant="outlined"
        onClick={() => {
          history.goBack();
        }}
      >
        Go Back
      </Button>
    </Stack>
  );
}
