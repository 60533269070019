import { WorkedBreakPolicyAcknowledgementPage } from "@src/appV2/Shifts/Shift/WorkedBreakAuthorization/WorkedBreakPolicyAcknowledgementPage";
import {
  type WorkedBreakAuthorizationParams,
  WorkedBreakRequestCreationPage,
} from "@src/appV2/Shifts/Shift/WorkedBreakAuthorization/WorkedBreakRequestCreationPage";
import { useState } from "react";

export interface WorkedBreakAuthorizationProps {
  onCancel: () => void;
  onSubmit: (data: WorkedBreakAuthorizationParams) => void;
  facilityName: string;
  mandatoryBreakPolicy: string;
}

export function WorkedBreakAuthorization(props: WorkedBreakAuthorizationProps): JSX.Element {
  const { onCancel, onSubmit, facilityName, mandatoryBreakPolicy } = props;
  const [activePage, setActivePage] = useState<"policy-acknowledgement" | "request-creation">(
    "policy-acknowledgement"
  );

  if (activePage === "request-creation") {
    return (
      <WorkedBreakRequestCreationPage
        facilityName={facilityName}
        onCancel={() => {
          setActivePage("policy-acknowledgement");
        }}
        onSubmit={onSubmit}
      />
    );
  }

  return (
    <WorkedBreakPolicyAcknowledgementPage
      facilityName={facilityName}
      mandatoryBreakPolicy={mandatoryBreakPolicy}
      onCancel={onCancel}
      onSubmit={() => {
        setActivePage("request-creation");
      }}
    />
  );
}
