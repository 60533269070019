import { isDefined } from "@clipboard-health/util-ts";
import { Box } from "@mui/material";
import { useGetChatChannels } from "@src/appV2/Facilities/Chat/api/useGetChatChannels";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";

import { ShiftCard } from "../../MyShifts/components/ShiftCard";
import { ShiftCardLoadingPlaceholder } from "../../MyShifts/components/ShiftCardLoadingPlaceholder";
import { useGetShift } from "../api/useGetShift";
import { WorkingShiftPlaceholder } from "./WorkingShiftPlaceholder";

export interface WorkingShiftProps {
  shiftId: string;
}

export function WorkingShift(props: WorkingShiftProps) {
  const { shiftId } = props;

  const worker = useDefinedWorker();

  const { isSuccess: isSuccessGetShift, data: getShiftData } = useGetShift(shiftId ?? "", {
    enabled: isDefined(shiftId),
  });

  const { isLoading: isLoadingChatChannels, data: chatChannelsData } = useGetChatChannels();

  const shift = isSuccessGetShift ? getShiftData?.response : undefined;
  const chatChannels = chatChannelsData?.channels ?? [];

  const isLoading = isSuccessGetShift && isLoadingChatChannels;

  return (
    <Box
      sx={{
        m: 3,
      }}
    >
      {isLoading && <ShiftCardLoadingPlaceholder />}
      {!isLoading && !shift && <WorkingShiftPlaceholder />}
      {!isLoading && shift && (
        <ShiftCard
          showShiftWorkingStatus
          worker={worker}
          shift={shift}
          chatChannels={chatChannels}
        />
      )}
    </Box>
  );
}
